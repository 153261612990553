<script lang="ts">
  import { browser } from "$app/environment";

  import { ChevronRightIcon } from "lucide-svelte";
  import { onDestroy } from "svelte";
  import { cubicOut } from "svelte/easing";
  import { fly, slide } from "svelte/transition";

  import LoginSignupButtons from "$lib/ui/common/LoginSignupButtons.svelte";

  export let open = false;
  export let includeUseCases = false;
  export let links: Array<{ href: string; name: string }> = [];
  export let paddingTop: string;

  const useCaseLinks = [
    { href: "/use-cases/language-models", name: "Language Inference" },
    { href: "/use-cases/image-video-3d", name: "Image, Video & 3D Inference" },
    { href: "/use-cases/audio", name: "Audio Inference" },
    { href: "/use-cases/fine-tuning", name: "Fine-tuning" },
    { href: "/use-cases/job-queues", name: "Job Queues & Batch Processing" },
    { href: "/use-cases/sandboxes", name: "Sandboxed Code Execution" },
  ];

  let useCasesOpen = false;

  function closeDropdowns() {
    open = false;
    useCasesOpen = false;
  }

  $: if (!open) {
    useCasesOpen = false;
  }

  $: if (browser) {
    if (open) {
      document.body.classList.add("overflow-hidden", "md:overflow-auto");
    } else {
      document.body.classList.remove("overflow-hidden", "md:overflow-auto");
    }
  }

  onDestroy(() => {
    if (browser) {
      // Make sure the classes are disabled if the component is destroyed.
      document.body.classList.remove("overflow-hidden", "md:overflow-auto");
    }
  });
</script>

<div
  class="fixed md:hidden inset-0 -z-10 h-full flex flex-col overflow-y-auto bg-black/70 backdrop-blur-xl opacity-0"
  class:opacity-100={open}
  class:pointer-events-none={!open}
  style:padding-top={paddingTop}
  style:transition={`opacity ${50 * (links.length + 1)}ms linear`}
>
  {#if open}
    <div
      class="pl-9 pr-8 py-4 border-b border-light-green/20"
      transition:fly|local={{ y: -15, delay: 0 }}
    >
      <LoginSignupButtons />
    </div>
    <div
      class="px-9 flex flex-col border-b border-light-green/20"
      class:hidden={!includeUseCases}
      transition:fly|local={{ y: -15, delay: 50 }}
    >
      <button
        class="my-4 flex items-center justify-between text-light-green"
        on:click={() => (useCasesOpen = !useCasesOpen)}
      >
        <span class="text-lg">Use Cases</span>
        <span
          class="opacity-60 transition-transform ease-out duration-300"
          style:transform={useCasesOpen ? "rotate(90deg)" : "rotate(0deg)"}
        >
          <ChevronRightIcon size={24} />
        </span>
      </button>
      {#if useCasesOpen}
        <div
          class="flex flex-col"
          transition:slide={{ easing: cubicOut, duration: 500 }}
        >
          {#each useCaseLinks as { href, name }}
            <a
              class="text-light-green/60 pl-6 mb-4"
              {href}
              on:click={closeDropdowns}
            >
              {name}
            </a>
          {/each}
        </div>
      {/if}
    </div>
  {/if}
  {#each open ? links : [] as { href, name }, i}
    <a
      class="px-9 py-4 text-lg text-light-green capitalize hover:text-brand-green border-b border-light-green/20"
      {href}
      on:click={closeDropdowns}
      transition:fly|local={{
        y: -15,
        delay: 50 * (i + (includeUseCases ? 2 : 1)),
      }}
    >
      {name}
    </a>
  {/each}
</div>
